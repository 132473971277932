import React from "react"
import "./style.css"
import Select from 'react-select'
import DataTable from 'react-data-table-component'
import { Header, NoData } from '../../components'
import { NumericFormat, PatternFormat } from 'react-number-format'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { cleanNumber, currency, momentViewDate, numFormat } from '../../library/utilities'
import { lang, ncf, paginationOptions, unitMeasurement } from '../../library/const'
import { Alert, Button, Card, Col, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap'
import { MDBills, MDProducts, MDCustomers } from "../../components/modals"
import { QRCodeSVG } from 'qrcode.react';

const View = (props) => {

    const {
        form, items, handleChange, handleSearchProduct, modals, setModals, handleSelectItem, onHold, columnsCustomers,
        handleRemoveItem, setSelectedRow, handlePlusOrMinus, handleInvoiceType, handleSaveInvoice, columnsPendingInvoices,
        handleReset, handleShowSalePrice, prices, handleShowChangePrice, errors, options, customer,
        handleCompletePayment, handlePaymentOptions, shifts, handleOpenShift, handleProducts, config, settings,
        handleProductInformation, columnsCashInOut, handleAddCashInOut, handleSelectedCustomer,
        getCashierClosingShift, handleCloseShift, dateRange, handleBoxClosures, columnsBoxClosures, shift, totals, search, setSearch,
        handlepayBill, services, handleAddEmpl, selectedRow, handleValidatesServicePayBill, auth, setPrint, handleGetCustomers
    } = props,
        filteredBoxClosures = (shift.listShiftClosure) ? shift.listShiftClosure : [],
        totalPesos = ((parseFloat(cleanNumber(form.totalCurrency)) || 0) + parseFloat(cleanNumber(form.totalBills || 0))) - ((modals.info?.opening + modals.info?.totalBox) - modals.info?.cashOut),
        totalDollars = (parseFloat(cleanNumber(form.totalDollars)) || 0) - modals.info?.dollars,
        totalCards = (parseFloat(cleanNumber(form.totalCard)) || 0) - modals.info?.card,
        totalTransfer = (parseFloat(cleanNumber(form.totalTransfer)) || 0) - modals.info?.transfer

    return (
        <>
            <Header />
            <section id="point-of-sale">
                <main id="main" className="main" style={{ marginLeft: 0 }}>
                    {!shifts.id &&
                        <Alert key="danger" variant="danger">
                            <i className="fas fa-lock"></i>&nbsp;&nbsp;<strong>Caja Cerrada</strong>, no se puede realizar ninguna acción mientras la caja está cerrada.
                        </Alert>}
                    <Card className="footer-pos">
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div className="d-grid gap-2">
                                        {shifts.id && <Button variant="danger" size="lg" type="submit" onClick={() => getCashierClosingShift(shifts.id)}>
                                            <i className="fas fa-cash-register"></i>&nbsp;&nbsp;Cierre de caja
                                        </Button>}
                                        {!shifts.id && <Button variant="info" size="lg" onClick={() => setModals({ ...modals, openShift: true })}>
                                            <i className="fas fa-cash-register"></i>&nbsp;&nbsp;Abrir de caja
                                        </Button>}
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-grid gap-2">
                                        <Button variant="secondary" size="lg" type="button" onClick={() => setModals({ ...modals, boxClosures: true })}>
                                            <i className="far fa-copy"></i>&nbsp;&nbsp;Cuadres de caja&nbsp;<small><strong>(F1)</strong></small>
                                        </Button>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-grid gap-2">
                                        <Button variant="secondary" size="lg" type="button" onClick={() => setModals({ ...modals, bills: true })}>
                                            <i className="fas fa-receipt"></i>&nbsp;&nbsp;Facturas&nbsp;<small><strong>(F12)</strong></small>
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs={2}>
                                    <div className="d-grid gap-2">
                                        <Button variant="secondary" disabled={!shifts.id} size="lg" type="button" onClick={() => setModals({ ...modals, cashInOut: true })}>
                                            <i className="fas fa-hand-holding-usd"></i>&nbsp;&nbsp;Entrada/Salida&nbsp;<small><strong>(F3)</strong></small>
                                        </Button>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" disabled={!shifts.id} size="lg" type="button" onClick={handleGetCustomers}>
                                            <i className="fas fa-users"></i>&nbsp;&nbsp;Clientes&nbsp;<small><strong>(F4)</strong></small>
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs={2}>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" disabled={!shifts.id || items.length > 0} size="lg" type="button" onClick={() => setModals({ ...modals, invoiceType: true })}>
                                            <i className="fas fa-receipt"></i>&nbsp;&nbsp;Tipo Factura&nbsp;<small><strong>(F5)</strong></small>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row className="head-block">
                        <Col xs={5}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col xs={7}>
                                            <strong>Cliente</strong>
                                            <label><i className="fas fa-user-tag"></i>&nbsp;&nbsp;{form.customer.name}</label>
                                        </Col>
                                        <Col xs={5}>
                                            <strong>Tipo de Factura</strong>
                                            <label>{form.invoiceType.label}</label>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={7}>
                            <Card className="totals-block">
                                <Card.Body>
                                    <Row>
                                        <Col xs={3}>
                                            <strong>SubTotal</strong>
                                            <label>{currency(totals.subTotal, "$ ")}</label>
                                        </Col>
                                        <Col xs={3}>
                                            <strong>Itbis</strong>
                                            <label>{currency(totals.itbis, "$ ")}</label>
                                        </Col>
                                        <Col xs={3}>
                                            <strong>Descuento</strong>
                                            <label>{currency(totals.discount, "$ ")}</label>
                                        </Col>
                                        <Col xs={3}>
                                            <strong>Total</strong>
                                            <label>{currency(totals.total, "$ ")}</label>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Card className="body-block">
                        <Card.Body>
                            <Row className="head-table mb-3">
                                <Col md={5}>
                                    <div className="search-product d-flex align-items-center">
                                        <InputGroup>
                                            <InputGroup.Text><i className="fas fa-barcode"></i></InputGroup.Text>
                                            <Form.Control autoFocus className='form-control-noline search' type="text" readOnly={!shifts.id} size="lg" placeholder="Buscar por código..."
                                                value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={handleSearchProduct} />
                                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={7} style={{ textAlign: "right" }}>
                                    <Button variant="primary" size="lg" disabled={!shifts.id} type="button" onClick={handleProducts}>
                                        <i className="fas fa-tags"></i>&nbsp;&nbsp;Productos&nbsp;<small><strong>(F8)</strong></small>
                                    </Button>&nbsp;&nbsp;{/*}
                                    <Button variant="light" size="lg" disabled={!shifts.id} type="button">
                                        <i className="fas fa-pause"></i>&nbsp;&nbsp;En espera ({onHold.length})
                                    </Button>&nbsp;&nbsp;
                                    <Button variant="secondary" size="lg" disabled={!shifts.id} type="button" onClick={handleSaveInvoice}>
                                        <i className="far fa-save"></i>&nbsp;&nbsp;Guardar
                                    </Button>&nbsp;&nbsp;{*/}
                                    <Button variant="danger" size="lg" type="button" id="optReset" disabled={!items.length} onClick={handleReset}>
                                        <i className="fas fa-sign-in-alt"></i>&nbsp;&nbsp;Cancelar&nbsp;<small><strong>(F7)</strong></small>
                                    </Button>&nbsp;&nbsp;
                                    <Button variant="success" size="lg" type="button" disabled={!items.length} onClick={handlepayBill}>
                                        <i className="fas fa-shopping-cart"></i>&nbsp;&nbsp;Pagar&nbsp;<small><strong>(F10)</strong></small>
                                    </Button>
                                </Col>
                            </Row>
                            <div className="body-table-items">
                                <Table striped hover size="sm" className="table-items">
                                    <thead>
                                        <tr>
                                            <th width="12%">C&oacute;digo</th>
                                            <th width="20%">Descripci&oacute;n</th>
                                            <th width="10%">Precio</th>
                                            <th width="10%">Cant.</th>
                                            <th width="10%">Importe</th>
                                            <th width="8%">Itbis</th>
                                            <th width="10%">Existencia</th>
                                            <th width="10%">Tipo Venta</th>
                                            <th width="10%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (items) &&
                                            items.map((row, key) => {
                                                return (
                                                    <tr key={key} onClick={() => setSelectedRow(key)} className={(selectedRow === key) ? "selected" : ""}>
                                                        <td>{row.code}</td>
                                                        <td>{row.description}</td>
                                                        <td>{currency(row.price, "$ ")}</td>
                                                        <td>
                                                            <button disabled={row.qty <= 1} className="btn btn-secondary btn-sm pointer" size="sm" data-tooltip-id="tooltip" data-tooltip-content="Restar cantidad (-)"
                                                                data-tooltip-place="top" type="button" onClick={() => handlePlusOrMinus(row, -1)}>
                                                                <i className="fas fa-minus"></i>
                                                            </button>
                                                            <span>
                                                                <NumericFormat thousandSeparator="," value={cleanNumber(row.qty)} name="qty" onChange={(e) => handleChange(e, "row", row)} />
                                                            </span>
                                                            <button className="btn btn-secondary btn-sm pointer" disabled={row.qty >= row.existence} size="sm" data-tooltip-id="tooltip" data-tooltip-content="Sumar cantidad (+)"
                                                                data-tooltip-place="top" type="button" onClick={() => handlePlusOrMinus(row, +1)}>
                                                                <i className="fas fa-plus"></i>
                                                            </button>
                                                        </td>
                                                        <td>{currency(row.amount, "$ ")}</td>
                                                        <td>{currency(row.totalItbis, "$ ")}</td>
                                                        <td>{numFormat(row.existence)}</td>
                                                        <td>{row.saleType}</td>
                                                        <td>
                                                            <div className="table-items-option">
                                                                <button className="btn btn-secondary btn-sm pointer" data-tooltip-id="tooltip" data-tooltip-content="Precio de venta (F2)"
                                                                    data-tooltip-place="left" type="button" onClick={() => handleShowSalePrice(row)}
                                                                    style={{ margin: "0 auto", width: "35px" }}>
                                                                    <i className="fas fa-dollar-sign"></i>
                                                                </button>&nbsp;
                                                                <button className="btn btn-secondary btn-sm pointer" data-tooltip-id="tooltip" data-tooltip-content="Información del producto (F9)"
                                                                    data-tooltip-place="left" type="button" onClick={() => handleProductInformation(row)}
                                                                    style={{ margin: "0 auto", width: "35px" }}>
                                                                    <i className="fas fa-info-circle"></i>
                                                                </button>&nbsp;
                                                                <button className="btn btn-danger btn-sm pointer" data-tooltip-id="tooltip" data-tooltip-content="Eliminar producto"
                                                                    data-tooltip-place="left" type="button" onClick={() => handleRemoveItem(row)}
                                                                    style={{ margin: "0 auto", width: "35px" }}>
                                                                    <i className="far fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                {!items.length && <NoData text="No hay productos para mostrar" />}
                            </div>
                        </Card.Body>
                    </Card>
                </main>
            </section>

            {/* Modal Bills */}
            <MDBills modal={modals.bills} handleClose={() => setModals({ ...modals, bills: false })} setPrint={setPrint} />

            {/* Modal List of products */}
            <MDProducts handleSelectItem={handleSelectItem} />

            {/* Modal Customers */}
            <MDCustomers handleSelectedCustomer={handleSelectedCustomer} />

            {/* Modal Invoice Type */}
            <Modal className="md-invoice-type" backdrop="static" show={modals.invoiceType} onHide={() => setModals({ ...modals, invoiceType: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-receipt"></i>&nbsp;&nbsp;Tipo de factura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-grid gap-2 mb-3">
                        <Button variant="secondary" size="lg" onClick={() => handleInvoiceType("without-tax-receipt")}>
                            <i className="fas fa-file-alt"></i>&nbsp;&nbsp;Factura Sin Comprobante
                        </Button>
                    </div>
                    {
                        config?.settings?.ncf?.map((r, k) => {
                            return (
                                r.active &&
                                <div className="d-grid gap-2 mb-3" key={k}>
                                    <Button variant="primary" disabled={!r.active || r.available <= 0} size="lg" onClick={() => handleInvoiceType(r.type)}>
                                        <i className="fas fa-file-invoice"></i>&nbsp;&nbsp;{ncf[r.type]}
                                    </Button>
                                    {r.available <= 0 && <Alert variant="danger">El rango de disponibilida de los NCF ({ncf[r.type]}) llego a limite.</Alert>}
                                </div>
                            )
                        })
                    }
                </Modal.Body>
            </Modal>

            {/* Modal Sale Price */}
            <Modal backdrop="static" show={modals.salePrice} onHide={() => setModals({ ...modals, salePrice: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-dollar-sign"></i>&nbsp;&nbsp;Precio de venta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        (prices.length) ?
                            prices.map((r, k) => {
                                return (
                                    <div className="d-grid gap-2 mb-3" key={k}>
                                        <Button variant={r.description.toLowerCase().includes("detalle") ? "primary" : "secondary"} disabled={!r.price} size="lg" onClick={() => handleShowChangePrice(r)}>
                                            <i className="fas fa-dollar-sign"></i>&nbsp;&nbsp;Precio {r.description} ({currency(r.price || 0, "$ ")})&nbsp;<small><strong>(Num. {k + 1})</strong></small>
                                        </Button>
                                    </div>)
                            })
                            : null
                    }
                </Modal.Body>
            </Modal>

            {/* Modal Payment Options */}
            <Modal id="md-payment-options" size="xl" backdrop="static" show={modals.paymentOptions} onHide={() => setModals({ ...modals, paymentOptions: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-shopping-cart"></i>&nbsp;&nbsp;Pagar Factura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="total-option">
                        <Col xs={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Total</Form.Label>
                                <strong>{currency(totals.total, "$ ")}</strong>
                            </Form.Group>
                        </Col>
                        <Col xs={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Por pagar</Form.Label>
                                <strong>{currency(((form.payable > 0) ? form.payable : 0), "$ ")}</strong>
                            </Form.Group>
                        </Col>
                        <Col xs={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Cambio</Form.Label>
                                <strong>{currency((form.change > 0) ? form.change : 0, "$ ")}</strong>
                            </Form.Group>
                        </Col>
                    </Row>
                    <h6>Método de pago</h6>
                    <Row className="payment-method">
                        <Col xs={1}></Col>
                        <Col xs={2}>
                            <div className="d-grid gap-2 mb-3">
                                <Button variant="light" size="lg" id="optCash" active={(form.paymentOption === "cash")} onClick={() => handlePaymentOptions("cash")}>
                                    <i className="fas fa-money-bill-wave fa-2x"></i>
                                    <span>Efectivo&nbsp;<small><strong>(F1)</strong></small></span>
                                </Button>
                            </div>
                        </Col>
                        <Col xs={2}>
                            <div className="d-grid gap-2 mb-3">
                                <Button variant="light" size="lg" id="optCard" active={(form.paymentOption === "card")} onClick={() => handlePaymentOptions("card")}>
                                    <i className="far fa-credit-card fa-2x"></i>
                                    <span>Tarjeta&nbsp;<small><strong>(F2)</strong></small></span>
                                </Button>
                            </div>
                        </Col>
                        <Col xs={2}>
                            <div className="d-grid gap-2 mb-3">
                                <Button variant="light" size="lg" id="optTransfer" active={(form.paymentOption === "transfer")} onClick={() => handlePaymentOptions("transfer")}>
                                    <i className="fas fa-exchange-alt fa-2x"></i>
                                    <span>Transferencia&nbsp;<small><strong>(F3)</strong></small></span>
                                </Button>
                            </div>
                        </Col>
                        {
                            auth?.branch?.credit &&
                            <Col xs={2}>
                                <div className="d-grid gap-2 mb-3">
                                    <Button variant="light" size="lg" id="optCredit" active={(form.paymentOption === "credit")} disabled={(!form.customer.cid || form.totalPending?.result <= 0)} onClick={() => handlePaymentOptions("credit")}>
                                        <i className="fas fa-wallet fa-2x"></i>
                                        <span>Crédito&nbsp;<small><strong>(F4)</strong></small></span>
                                    </Button>
                                </div>
                            </Col>
                        }
                        <Col xs={2}>
                            <div className="d-grid gap-2 mb-3">
                                <Button variant="light" size="lg" id="optCombined" active={(form.paymentOption === "combined")} onClick={() => handlePaymentOptions("combined")}>
                                    <i className="fas fa-money-bill-wave fa-2x"></i>&nbsp;&nbsp;&nbsp;<i className="far fa-credit-card fa-2x"></i>
                                    <span>Combinado&nbsp;<small><strong>(F5)</strong></small></span>
                                </Button>
                            </div>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    {
                        form.totalPending?.limit > 0 && form.totalPending?.result <= 0 &&
                        <Alert variant="danger">Este cliente no puede realizar más compras a crédito, ya que ha superado el límite de {currency(form.totalPending?.limit, "$")}. Actualmente tiene una deuda pendiente de {currency(form.totalPending?.pending, "$")}.</Alert>
                    }
                    <Row>
                        {/* Cash */}
                        <Col xs={form.paymentOption === "combined" ? 6 : 12}>
                            {
                                (form.paymentOption === "cash" || form.paymentOption === "combined") &&
                                <div className="option-cash">
                                    <h6>Efectivo</h6>
                                    <Row>
                                        <Col xs={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.pesos ? "required-label" : "")}>Peso</Form.Label>
                                                <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.pesos ? "required-input" : "")}
                                                    placeholder="00.00" value={(form.pesos === 0) ? "" : form.pesos} name="pesos" onChange={handleChange}
                                                    autoFocus={form.paymentOption === "cash" || form.paymentOption === "combined"} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.id ? "required-label" : "")}>Dollar <small>(USD$1 = RD${settings.rate})</small></Form.Label>
                                                <div className="dollars">
                                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.dollars ? "required-input" : "")}
                                                        placeholder="00.00" value={(form.dollars === 0) ? "" : form.dollars} name="dollars" onChange={handleChange} />
                                                    <NumericFormat thousandSeparator="," readOnly className="form-control form-control-lg" placeholder="00.00"
                                                        value={(form.dollarsChange === 0) ? "" : form.dollarsChange} />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Col>

                        {/* Card */}
                        <Col xs={form.paymentOption === "combined" ? 6 : 12}>
                            {
                                (form.paymentOption === "card" || form.paymentOption === "combined") &&
                                <div className="option-card">
                                    <h6>Tarjeta</h6>
                                    <Row>
                                        {
                                            form.paymentOption === "combined" &&
                                            <Col xs={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className={(errors.cardAmount ? "required-label" : "")}>Cantidad</Form.Label>
                                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.cardAmount ? "required-input" : "")}
                                                        placeholder="00.00" value={form.cardAmount} name="cardAmount" onChange={handleChange} />
                                                </Form.Group>
                                            </Col>
                                        }
                                        <Col xs={form.paymentOption === "combined" ? 4 : 6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.card ? "required-label" : "")}>Tarjeta</Form.Label>
                                                <PatternFormat className={"form-control form-control-lg " + (errors.card ? "required-input" : "")}
                                                    placeholder="####" format="####" value={form.card} name="card" onChange={handleChange} autoFocus={form.paymentOption === "card"} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={form.paymentOption === "combined" ? 4 : 6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.approval ? "required-label" : "")}>Num. Aprobación</Form.Label>
                                                <NumericFormat className={"form-control form-control-lg " + (errors.approval ? "required-input" : "")}
                                                    placeholder="#######" value={form.approval} name="approval" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Col>

                        {/* Transfer */}
                        <Col xs={form.paymentOption === "combined" ? 6 : 12}>
                            {
                                (form.paymentOption === "transfer" || form.paymentOption === "combined") &&
                                <div className="option-transfer">
                                    <h6>Transferencia</h6>
                                    <Row>
                                        {
                                            form.paymentOption === "combined" &&
                                            <Col xs={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className={(errors.transferAmount ? "required-label" : "")}>Cantidad</Form.Label>
                                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.transferAmount ? "required-input" : "")}
                                                        placeholder="00.00" value={form.transferAmount} name="transferAmount" onChange={handleChange} />
                                                </Form.Group>
                                            </Col>
                                        }
                                        <Col xs={form.paymentOption === "combined" ? 5 : 6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.idBank ? "required-label" : "")}>Banco</Form.Label>
                                                <Select options={options.banks} placeholder="Selecionar..." value={form.idBank}
                                                    name="idBank" onChange={(e) => handleChange(e, "idBank")} autoFocus={form.paymentOption === "transfer"} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={form.paymentOption === "combined" ? 4 : 6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className={(errors.transApproval ? "required-label" : "")}>Num. Aprobación</Form.Label>
                                                <Form.Control className={"form-control form-control-lg " + (errors.transApproval ? "required-input" : "")}
                                                    placeholder="########" value={form.transApproval} name="transApproval" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Col>
                        {/* Credit */}
                        <Col xs={form.paymentOption === "combined" ? 6 : 12}>
                            {
                                (auth?.branch?.credit && (form.paymentOption === "credit" || (form.paymentOption === "combined" && form.customer.cid))) &&
                                <div className="option-credit">
                                    <h6>Crédito</h6>
                                    <Row>
                                        {
                                            form.paymentOption === "combined" &&
                                            <Col xs={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className={(errors.pesos ? "required-label" : "")}>Cantidad</Form.Label>
                                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.pesos ? "required-input" : "")}
                                                        placeholder="00.00" value={form.creditAmount} name="creditAmount" onChange={handleChange} />
                                                </Form.Group>
                                            </Col>
                                        }
                                        <Col xs={form.paymentOption === "combined" ? 4 : 6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Cliente</Form.Label>
                                                <span className="view-span">{form.customer.name}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={form.paymentOption === "combined" ? 4 : 6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Deuda Pendiente</Form.Label>
                                                <span className="view-span">{currency(form.totalPending?.pending || 0, "$ ")}</span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Col>
                    </Row>
                    {
                        errors.payable &&
                        <Alert variant="danger">Aún queda un balance pendiente de {currency(form.payable, "$")} por pagar, no puede completar la venta hasta que el total sea cero.</Alert>
                    }
                    <Modal.Footer>
                        <Button variant="success" size="lg" id="completePayment" onClick={handleCompletePayment}>
                            <i className="fas fa-shopping-cart"></i>&nbsp;&nbsp;Completar Pago&nbsp;<small><strong>(F10)</strong></small>
                        </Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

            {/* Modal Product Information */}
            <Modal backdrop="static" size="lg" id="md-product-information" show={modals.productInformation} onHide={() => setModals({ ...modals, productInformation: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-receipt"></i>&nbsp;&nbsp;Información del Producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>C&oacute;digo</Form.Label>
                                <span className="view-span">{modals.info?.code}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Descripci&oacute;n</Form.Label>
                                <span className="view-span">{modals.info?.description}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Unidad de Medida</Form.Label>
                                <span className="view-span">{unitMeasurement[modals.info?.unitMeasurement]}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <h5>Precios</h5>
                    <Row>
                        {
                            modals.info?.prices && modals.info?.prices.map((r, k) => {
                                return (
                                    <Col md={3} key={k}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{r.description}</Form.Label>
                                            <span className="view-span">{(r.price) ? currency(r.price, "$ ") : "-"}</span>
                                        </Form.Group>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <h5>Existencia</h5>
                    <Table striped size="sm" className="table-items">
                        <thead>
                            <tr>
                                <th>Sucursal</th>
                                <th>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                modals.info?.quantity && modals.info?.quantity.map((r, k) => {
                                    return (
                                        <tr key={k}>
                                            <td>{r.name}</td>
                                            <td>{r.quantity}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, productInformation: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Cash In Out */}
            <Modal backdrop="static" size="xl" show={modals.cashInOut} onHide={() => setModals({ ...modals, cashInOut: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-receipt"></i>&nbsp;&nbsp;Entrada y Salida Efectivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{ width: 850 }}>
                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.typeCashInOut ? "required-label" : "")}>Tipo&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Select options={options.typeCashInOut} placeholder="Selecionar..." value={form.typeCashInOut}
                                        name="typeCashInOut" onChange={(e) => handleChange(e, "typeCashInOut")} autoFocus={true} />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.amount ? "required-label" : "")}>Cantidad&nbsp;<span className="required-label">*</span></Form.Label>
                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg" + (errors.amount ? "required-input" : "")} placeholder="##.##"
                                        value={form.amount} name="amount" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={(errors.comment ? "required-label" : "")}>Comentario&nbsp;<span className="required-label">*</span></Form.Label>
                                    <Form.Control className={(errors.comment ? "required-input" : "")} type="text" size="lg" placeholder="Escriba la comentario..."
                                        value={form.comment} name="comment" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <div className="d-grid gap-2">
                                    <Button variant="success" size="lg" className="mt-4" onClick={handleAddCashInOut}>
                                        <i className="far fa-save"></i>&nbsp;&nbsp;Guardar
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <div className="table-responsive">
                        <DataTable
                            columns={columnsCashInOut}
                            data={shifts.cashInOut}
                            pagination
                            striped
                            paginationComponentOptions={paginationOptions}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, cashInOut: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Open Shift */}
            <Modal backdrop="static" id="md-open-shift" show={modals.openShift} onHide={() => setModals({ ...modals, openShift: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-cash-register"></i>&nbsp;&nbsp;Abrir Caja</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label className={(errors.opening ? "required-label" : "")}>Efectivo en caja</Form.Label>
                        <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.opening ? "required-input" : "")}
                            placeholder="00.00" value={(form.opening === 0) ? "" : form.opening} name="opening" onChange={handleChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="lg" onClick={handleOpenShift}>
                        <i className="fas fa-lock-open"></i>&nbsp;&nbsp;Abrir Turno
                    </Button>&nbsp;
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, openShift: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Close Shift */}
            <Modal backdrop="static" size="lg" id="md-close-shift" show={modals.closeShift} onHide={() => setModals({ ...modals, closeShift: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-cash-register"></i>&nbsp;&nbsp;Cierre de Caja</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Pesos</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.pesos, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Dolares</Form.Label>
                                <span className="view-span">{currency(modals.info?.dollars, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Tarjeta</Form.Label>
                                <span className="view-span">{currency(modals.info?.card, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Transferencia</Form.Label>
                                <span className="view-span">{currency(modals.info?.transfer, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Cr&eacute;dito</Form.Label>
                                <span className="view-span">{currency(modals.info?.credit, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Pagos a deuda</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.debtPayment, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Entrada de efectivo</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.cashIn, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Salida de efectivo</Form.Label>
                                <span className="view-span vivid-red">{currency(modals.info?.cashOut, "$ ")}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Total en Peso</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.totalBox - modals.info?.cashOut, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Apertura</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.opening, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Total en caja</Form.Label>
                                <span className="view-span dark-green">{currency((modals.info?.opening + modals.info?.totalBox) - modals.info?.cashOut, "$ ")}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="currency">
                        <h6>Total contado</h6>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Label className={(errors.totalCurrency ? "required-label" : "")}>Moneda</Form.Label>
                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.totalCurrency ? "required-input" : "")}
                                        placeholder="00.00" value={form.totalCurrency} name="totalCurrency" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Label className={(errors.totalBills ? "required-label" : "")}>Billete</Form.Label>
                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.totalBills ? "required-input" : "")}
                                        placeholder="00.00" value={form.totalBills} name="totalBills" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Label className={(errors.totalDollars ? "required-label" : "")}>Dolares</Form.Label>
                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.totalDollars ? "required-input" : "")}
                                        placeholder="00.00" value={form.totalDollars} name="totalDollars" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Label className={(errors.totalCard ? "required-label" : "")}>Tarjeta</Form.Label>
                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.totalCard ? "required-input" : "")}
                                        placeholder="00.00" value={form.totalCard} name="totalCard" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Label className={(errors.totalTransfer ? "required-label" : "")}>Transferencia</Form.Label>
                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.totalTransfer ? "required-input" : "")}
                                        placeholder="00.00" value={form.totalTransfer} name="totalTransfer" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </div>
                    <div className="currency">
                        <h6 className="mt-4">Total Restante</h6>
                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Peso</Form.Label>
                                    <span className="view-span" style={{ color: totalPesos === 0 ? "#0f6382" : (totalPesos > 0 ? "#198754" : "#dc3545") }}>
                                        {currency(totalPesos, "$ ")}
                                    </span>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Dolares</Form.Label>
                                    <span className="view-span" style={{ color: totalDollars === 0 ? "#0f6382" : (totalDollars > 0 ? "#198754" : "#dc3545") }}>{currency(totalDollars, "$ ")}</span>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Tarjeta</Form.Label>
                                    <span className="view-span" style={{ color: totalCards === 0 ? "#0f6382" : (totalCards > 0 ? "#198754" : "#dc3545") }}>{currency(totalCards, "$ ")}</span>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Transferencia</Form.Label>
                                    <span className="view-span" style={{ color: totalTransfer === 0 ? "#0f6382" : (totalTransfer > 0 ? "#198754" : "#dc3545") }}>{currency(totalTransfer, "$ ")}</span>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="lg" onClick={handleCloseShift}>
                        <i className="fas fa-lock"></i>&nbsp;&nbsp;Cerrar Turno
                    </Button>&nbsp;
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, closeShift: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Box Closures */}
            <Modal backdrop="static" size="xl" id="md-box-closures" show={modals.boxClosures} onHide={() => setModals({ ...modals, boxClosures: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-cash-register"></i>&nbsp;&nbsp;Cuadres de caja</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Rango de fecha</Form.Label>
                            <DateRangePicker locale={lang} onChange={handleBoxClosures} value={dateRange.boxClosures} />
                        </Form.Group>
                    </Form>
                    <div className="table-responsive">
                        <DataTable
                            columns={columnsBoxClosures}
                            data={filteredBoxClosures}
                            pagination striped
                            noDataComponent={<NoData />}
                            paginationComponentOptions={paginationOptions}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, boxClosures: false })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal view close Shift */}
            <Modal backdrop="static" size="lg" id="md-view-close-shift" show={modals.viewBoxClosures} onHide={() => setModals({ ...modals, viewBoxClosures: false, boxClosures: true })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-cash-register"></i>&nbsp;&nbsp;Cierre de Caja</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Fecha Apertura</Form.Label>
                                <span className="view-span">{momentViewDate(modals.info?.opening_date)}</span>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Fecha Cierre</Form.Label>
                                <span className="view-span">{momentViewDate(modals.info?.closing_date)}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={1}></Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Pesos</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.pesos, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Dolares</Form.Label>
                                <span className="view-span">{currency(modals.info?.dollars, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Tarjeta</Form.Label>
                                <span className="view-span">{currency(modals.info?.card, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Transferencia</Form.Label>
                                <span className="view-span">{currency(modals.info?.transfer, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Cr&eacute;dito</Form.Label>
                                <span className="view-span">{currency(modals.info?.credit, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Pagos a deuda</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.debtPayment, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Entrada de efectivo</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.cashIn, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Salida de efectivo</Form.Label>
                                <span className="view-span vivid-red">{currency(modals.info?.cashOut, "$ ")}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Total en Peso</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.totalBox, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Apertura</Form.Label>
                                <span className="view-span dark-teal">{currency(modals.info?.opening, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Total en caja</Form.Label>
                                <span className="view-span dark-green">{currency(modals.info?.opening + modals.info?.totalBox, "$ ")}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <h6 className="mt-3">Total contado</h6>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Moneda</Form.Label>
                                <span className="view-span">{currency(modals.info?.totalCurrency, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Billete</Form.Label>
                                <span className="view-span">{currency(modals.info?.totalBills, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Dolares</Form.Label>
                                <span className="view-span">{currency(modals.info?.totalDollars, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Tarjeta</Form.Label>
                                <span className="view-span">{currency(modals.info?.totalCard, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Transferencia</Form.Label>
                                <span className="view-span">{currency(modals.info?.totalTransfer, "$ ")}</span>
                            </Form.Group>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                    <h6 className="mt-4">Total Restante</h6>
                    <Row className="mb-3">
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Peso</Form.Label>
                                <span className="view-span" style={{ color: modals.info?.totalPesosCount === 0 ? "#0f6382" : (modals.info?.totalPesosCount > 0 ? "#198754" : "#dc3545") }}>
                                    {currency(modals.info?.totalPesosCount, "$ ")}
                                </span>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Dolares</Form.Label>
                                <span className="view-span" style={{ color: modals.info?.totalDollarsCount === 0 ? "#0f6382" : (modals.info?.totalDollarsCount > 0 ? "#198754" : "#dc3545") }}>
                                    {currency(modals.info?.totalDollarsCount, "$ ")}
                                </span>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Tarjeta</Form.Label>
                                <span className="view-span" style={{ color: modals.info?.totalCardsCount === 0 ? "#0f6382" : (modals.info?.totalCardsCount > 0 ? "#198754" : "#dc3545") }}>
                                    {currency(modals.info?.totalCardsCount, "$ ")}
                                </span>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Transferencia</Form.Label>
                                <span className="view-span" style={{ color: modals.info?.totalTransferCount === 0 ? "#0f6382" : (modals.info?.totalTransferCount > 0 ? "#198754" : "#dc3545") }}>
                                    {currency(modals.info?.totalTransferCount, "$ ")}
                                </span>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={() => setModals({ ...modals, viewBoxClosures: false, boxClosures: true })}>
                        <i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Employee */}
            <Modal backdrop="static" id="md-employee" keyboard={false} size="xl" show={modals.employees} onHide={() => setModals({ ...modals, employees: false })}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-tools"></i>&nbsp;&nbsp;Servicios</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="services-table-wrap">
                        <Row>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label className={(errors.svr ? "required-label" : "")}>Servicio</Form.Label>
                                    <Select options={services.opt} placeholder="Selecionar..."
                                        value={form.svr} name="svr" onChange={(e) => handleChange(e, "svr")} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label className={(errors.empsvr ? "required-label" : "")}>Empelado</Form.Label>
                                    <Select options={services.emps} placeholder="Selecionar..."
                                        value={form.empsvr} name="empsvr" onChange={(e) => handleChange(e, "empsvr")} />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                    <Form.Label className={(errors.countSrv ? "required-label" : "")}>Cantidad</Form.Label>
                                    <NumericFormat thousandSeparator="," className={"form-control form-control-lg " + (errors.countSrv ? "required-input" : "")}
                                        placeholder="00.00" value={form.countSrv} name="countSrv" onChange={handleChange} />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Button variant="success" size="lg" className="mt-4" onClick={handleAddEmpl}>
                                    <i className="fas fa-check"></i>&nbsp;&nbsp;Add
                                </Button>
                            </Col>
                        </Row>
                        <Table striped hover size="sm" className="table-items mt-3">
                            <thead>
                                <tr>
                                    <th width="35%">&nbsp;&nbsp;Servicio</th>
                                    <th width="40%">Empelado</th>
                                    <th width="20%" style={{ textAlign: "center" }}>Cantidad</th>
                                    <th width="5%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    services.data && services.data.map((r, k) => {
                                        return (
                                            <tr key={k}>
                                                <td>&nbsp;&nbsp;{r.srid.label}</td>
                                                <td>{r.eid.label}</td>
                                                <td align="center">{r.count}</td>
                                                <td align="center">
                                                    <button className="btn btn-danger btn-sm pointer" data-tooltip-id="tooltip" data-tooltip-content="Eliminar"
                                                        data-tooltip-place="left" type="button" onClick={() => handleRemoveItem(r, 'service')}
                                                        style={{ margin: "0 auto", width: "35px" }}>
                                                        <i className="far fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        {!services.data?.length && <NoData text="No hay empleados para mostrar" />}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="lg" onClick={handleValidatesServicePayBill}>
                        <i className="fas fa-shopping-cart"></i>&nbsp;&nbsp;Pagar Factura
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default View