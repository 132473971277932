import React, { useEffect, useState, useRef } from 'react'
import View from './view'
import { connect } from 'react-redux'
import { confirmError, confirmOption } from '../../components/confirm-alert'
import inventoryActions from '../../redux/actions/inventory'
import customerActions from '../../redux/actions/customers'
import configActions from '../../redux/actions/config'
import shiftActions from '../../redux/actions/shifts'
import billActions from '../../redux/actions/bill'
import { ucwords, cleanNumber, currency, addTaxes, naiveRound, momentViewDate } from '../../library/utilities'
import { cashInOut, idType, typeCustomer } from '../../library/const'
import { Button } from 'react-bootstrap'
import momentTz from 'moment-timezone'
import { useReactToPrint } from 'react-to-print'
import { PrintInvoiceReceipt, PrintShiftClosure } from '../../receipts'
import humanResourcesActions from '../../redux/actions/human-resources'

const PointOfSale = ({
    inventory, customer, setSearchProduct, bill, getOpenShift, getCustomers, setBill, config,
    shift, setOpenShift, getProductsByBranchOffices, setUpdateShift, getCashierClosingShift,
    getSetting, getShiftClosureByUserDate, humanResources, getEmployees, getProduct, auth, getBanks
}) => {

    const
        initForm = {
            code: "", codeItem: "", description: "", cardAmount: "", typeCustomer: [], idBank: [], transApproval: "",
            invoiceType: { value: 'without-tax-receipt', label: 'Sin Comprobante' }, selectedRowModal: -1, transferAmount: "",
            customer: { cid: "", name: "Genérico" }, idType: [], cityProvince: [], id: "", cid: "", bid: "",
            searchCustomer: "", cash: 0, dollars: 0, paymentOption: "", payable: 0, comment: "", totalTransfer: "",
            change: 0, dollarsChange: 0, searchProduct: "", debtPayment: [], typeCashInOut: [], amount: "",
            totalPending: { pending: 0, limit: 0, result: 0 }
        },
        initModals = {
            customers: false, invoiceType: false, productInformation: false,
            paymentOptions: false, bills: false, openShift: false, cashInOut: false,
            title: "", icon: "", info: {}, closeShift: false
        },
        [settings, setSettings] = useState({
            rate: 0,
            ccRate: { active: false, rate: 0 }
        }),
        [totals, setTotals] = useState({
            subTotal: 0,
            itbis: 0,
            discount: 0,
            total: 0,
            ccTax: 0
        }),
        [errors, setErrors] = useState({}),
        [shifts, setShifts] = useState({ id: "", cashInOut: [] }),
        [options, setOptions] = useState({
            citiesProvinces: config.citiesProvinces,
            banks: [],
            idType: [
                { value: 'identification-card', label: idType['identification-card'] },
                { value: 'rnc', label: idType.rnc },
                { value: 'passport', label: idType.passport }
            ],
            typeCashInOut: [
                { value: 'cash-in', label: cashInOut['cash-in'] },
                { value: 'cash-out', label: cashInOut['cash-out'] }
            ], typeCustomer: [
                { value: 'retail', label: typeCustomer.retail },
                { value: 'wholesale', label: typeCustomer.wholesale },
            ]
        }),
        [form, setForm] = useState(initForm),
        [search, setSearch] = useState(""),
        [print, setPrint] = useState(null),
        [services, setServices] = useState({ opt: [], emps: [], data: [] }),
        [modals, setModals] = useState(initModals),
        [items, setItems] = useState([]),
        [selectedRow, setSelectedRow] = useState(-1),
        [prices, setPrices] = useState([]),
        [onHold, setOnHold] = useState([]),
        [dateRange, setDateRange] = useState({
            bills: [null, null],
            boxClosures: [null, null]
        }), columnsCashInOut = [{
            name: 'Tipo', minWidth: '15%', maxWidth: '15%', sortable: false, cell: row => (
                <span>{cashInOut[row.type]}</span>
            )
        }, {
            name: 'Total', minWidth: '15%', maxWidth: '15%', sortable: false, cell: row => (
                <span>{currency(row.amount, "$ ")}</span>
            )
        }, {
            name: 'Comentario', minWidth: '64%', maxWidth: '64%', sortable: false, cell: row => (
                <span>{row.comment}</span>
            )
        }, {
            name: '', minWidth: '5%', maxWidth: '5%', cell: (row, key) => (
                <div>
                    <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Eliminar"
                        data-tooltip-place="right" type="button" style={{ width: "35px" }}
                        onClick={() => handleCashInOut(key)}>
                        <i className="far fa-trash-alt"></i>
                    </Button>
                </div>
            )
        }
        ], columnsBoxClosures = [{
            name: 'Fecha Apertura', minWidth: '23%', maxWidth: '23%', sortable: false, cell: row => (
                <span>{momentViewDate(row.opening_date)}</span>
            )
        }, {
            name: 'Fecha Cierre', minWidth: '23%', maxWidth: '23%', sortable: false, cell: row => (
                <span>{momentViewDate(row.closing_date)}</span>
            )
        }, {
            name: 'Apertura', minWidth: '14%', maxWidth: '14%', sortable: false, cell: row => (
                <span>{currency(row.opening, "$ ")}</span>
            )
        }, {
            name: 'En Caja', minWidth: '15%', maxWidth: '15%', sortable: false, cell: row => (
                <span>{currency(row.closingTotal.cash, "$ ")}</span>
            )
        }, {
            name: 'Contado', minWidth: '15%', maxWidth: '15%', sortable: false, cell: row => (
                <span>{currency(row.countedTotal.coins + row.countedTotal.bills, "$ ")}</span>
            )
        }, {
            name: '', minWidth: '10%', maxWidth: '10%', cell: (row, key) => (
                <div style={{ width: "100%", textAlign: 'end' }}>
                    <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Imprimir Cuadre"
                        data-tooltip-place="right" type="button" style={{ width: "35px" }} onClick={() => handleViewBoxClosures(row, "print")}>
                        <i className="fas fa-print"></i>
                    </Button>&nbsp;&nbsp;
                    <Button variant="secondary" className="pointer" size='sm' data-tooltip-id="tooltip" data-tooltip-content="Ver Cuadre"
                        data-tooltip-place="right" type="button" style={{ width: "35px" }}
                        onClick={() => handleViewBoxClosures(row)}>
                        <i className="far fa-file-alt"></i>
                    </Button>
                </div>
            )
        }],
        printInvoiceReceipt = useRef(),
        printShiftClosure = useRef(),
        handlePrintInvoiceReceipt = useReactToPrint({
            content: () => printInvoiceReceipt.current,
            onAfterPrint: () => setPrint(null)
        }),
        handlePrintShiftClosure = useReactToPrint({
            content: () => printShiftClosure.current,
            onAfterPrint: () => setPrint(null)
        })

    // Detect when a key is pressed
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown)
    }, [])

    // Set banks options
    useEffect(() => {
        if (config.banks && config.banks?.length) setOptions({ ...options, banks: config.banks })
    }, [config.banks])

    //Configuration settings
    useEffect(() => {
        if (config.settings?.dollar && config.settings?.creditCard) {
            setSettings({
                rate: config.settings.dollar.result,
                ccRate: config.settings.creditCard
            })
        }
    }, [config.settings])

    // Print shift detail
    useEffect(() => {
        if (shift.shiftDetail) {
            const row = shift.shiftDetail, pesos = row.closingTotal.cash, debtPayments = row.closingTotal.debtPayments,
                cashIn = row.cashInOut.reduce((acc, curr) => {
                    if (curr.type === "cash-in") {
                        return acc + curr.amount
                    } else {
                        return acc
                    }
                }, 0), cashOut = row.cashInOut.reduce((acc, curr) => {
                    if (curr.type === "cash-out") {
                        return acc + curr.amount
                    } else {
                        return acc
                    }
                }, 0), totalBox = (pesos + cashIn + debtPayments) - cashOut
            setPrint({
                typePrint: "shiftClosure",
                opening_date: row.opening_date,
                closing_date: row.closing_date,
                credit: row.closingTotal.credit,
                card: row.closingTotal.card,
                pesos: pesos,
                debtPayment: debtPayments,
                cashIn: cashIn,
                cashOut: cashOut,
                opening: row.opening,
                dollars: row.closingTotal.dollars,
                totalBox: totalBox,
                totalCurrency: row.countedTotal.coins,
                totalBills: row.countedTotal.bills,
                totalDollars: row.countedTotal.dollars,
                totalCard: row.countedTotal.card,
                totalTransfer: row.countedTotal.transfer,
                totalPesos: totalBox - (row.countedTotal.coins + row.countedTotal.bills) - cashOut,
                totalPesosCount: (row.countedTotal.coins + row.countedTotal.bills) - (totalBox + row.opening),
                totalDollarsCount: (row.countedTotal.dollars - row.closingTotal.dollars),
                totalCardsCount: (row.countedTotal.card - row.closingTotal.card),
                totalTransferCount: (row.countedTotal.transfer - row.closingTotal.transfer),
                user: row.uid[0].name,
                branch: row.boid[0].name
            })
        }
    }, [shift.shiftDetail])

    // Detect when a key is pressed
    useEffect(() => {
        getOpenShift()
        getSetting()
        getBanks()
    }, [getOpenShift, getSetting, getBanks])

    // Check the status of the shift
    useEffect(() => {
        if (shift?.shift && !modals.cashInOut) {
            const { _id, cashInOut } = shift.shift
            setShifts({ id: _id, cashInOut: cashInOut || null })
        }
    }, [shift.shift])

    // Closes the modal when opening the tur
    useEffect(() => {
        setForm({ ...form, opening: "" })
        setModals({ ...modals, openShift: false, closeShift: false })
    }, [shifts])

    // If there is a product, it is added to the list of items
    useEffect(() => {
        const { product } = inventory
        if (product && product._id) {
            if (product.qty > 0) {
                handleAddProduct(product)
            } else {
                confirmError("Producto sin existencias", "Este producto se encuentra sin existencias disponibles. ")
            }
        }
    }, [inventory.product])

    // Show product information
    useEffect(() => {
        const { productInformation } = inventory
        if (productInformation && productInformation._id) {
            setModals({ ...modals, productInformation: true, info: productInformation })
        }
    }, [inventory.productInformation])

    // Update totals when editing items
    useEffect(() => {
        handleSumTotal()
    }, [items])

    // Calculate the change
    useEffect(() => {
        const pesos = (form.pesos) ? parseFloat(cleanNumber(form.pesos)) : 0,
            dollarsChange = (form.dollars) ? parseFloat(cleanNumber(form.dollars)) * settings.rate : 0,
            cardAmount = (form.cardAmount) ? parseFloat(cleanNumber(form.cardAmount)) : 0,
            creditAmount = (form.creditAmount) ? parseFloat(cleanNumber(form.creditAmount)) : 0,
            transferAmount = (form.transferAmount) ? parseFloat(cleanNumber(form.transferAmount)) : 0,
            payable = totals.total - (pesos + dollarsChange + cardAmount + creditAmount + transferAmount),
            change = (pesos + dollarsChange + cardAmount) - totals.total
        setForm({
            ...form,
            change: change,
            payable: payable,
            dollarsChange: naiveRound(dollarsChange, 2)
        })
    }, [form.pesos, form.dollars, form.cardAmount, form.creditAmount, form.transferAmount, totals.total])

    // Cashier closing
    useEffect(() => {
        if (shift.cashierClosing) {
            const { totals, debtPayment, cashInOut } = shift.cashierClosing,
                cashIn = cashInOut[0].totalCashIn, cashOut = cashInOut[0]?.totalCashOut || 0,
                debtPayments = debtPayment[0]?.total || 0

            let credit = 0, transfer = 0, card = 0, pesos = 0, dollars = 0
            for (let [inx, elm] of totals.entries()) {
                credit += elm.totalCredit
                transfer += elm.totalTransfer
                card += elm.totalCard
                pesos += elm.totalCashPeso
                dollars += elm.totalCashDollars
            }

            setModals({
                ...modals, info: {
                    opening: shift.shift.opening,
                    credit: credit,
                    card: card,
                    transfer: transfer,
                    pesos: pesos,
                    cashIn: cashIn,
                    cashOut: cashOut,
                    dollars: dollars,
                    debtPayment: debtPayments,
                    totalBox: (pesos + cashIn + debtPayments),
                }, closeShift: true
            })
        }
    }, [shift.cashierClosing])

    // Print the receipt
    useEffect(() => {
        if (print?.typePrint === "invoice") handlePrintInvoiceReceipt()
        if (print?.typePrint === "shiftClosure") handlePrintShiftClosure()
    }, [print])

    // Get list of employee
    useEffect(() => {
        if (humanResources.employees?.length) {
            const emps = []
            humanResources.employees.forEach(r => {
                emps.push({ value: r._id, label: ucwords(r.name + " " + r.lastName) })
            })
            setServices({ ...services, emps: emps })
        }
    }, [humanResources.employees])

    // Show services modals
    useEffect(() => {
        if (services.emps?.length) setModals({ ...modals, employees: true })
    }, [services.emps])

    // Get total pending
    useEffect(() => {
        const { pending, limit } = bill.totalPending
        setForm({ ...form, totalPending: { pending, limit, result: limit - pending - totals.total } })
    }, [bill.totalPending, totals.total])

    /**
     * Handle box closures
     * @param {*} e 
     */
    const handleBoxClosures = (e) => {
        setDateRange({ ...dateRange, boxClosures: e })
        const dinit = momentTz(e[0]).tz('America/Los_Angeles').format("YYYY-MM-DD"),
            dend = momentTz(e[1]).tz('America/Los_Angeles').format("YYYY-MM-DD")
        getShiftClosureByUserDate(dinit, dend)
    }

    /**
     * Handle ssum total
     */
    const handleSumTotal = (itms = items) => {
        let subTotal = 0, itbis = 0, total = 0, opt = []
        itms.forEach(row => {
            subTotal += row.qty * row.price
            itbis += row.totalItbis
            if (row.chid) {
                opt.push({ value: row.pid, label: row.description + " (" + row.qty + ")", count: row.qty })
            }
        })
        total = subTotal + itbis
        setTotals({ subTotal: subTotal, itbis: itbis, total: total, discount: 0 })
        setServices({ ...services, opt: opt })
    }

    /**
     * Handle view box closures
     * @param {*} row 
     */
    const handleViewBoxClosures = (row, type = "view") => {
        const pesos = row.closingTotal.cash, debtPayments = row.closingTotal.debtPayments,
            cashIn = row.cashInOut.reduce((acc, curr) => {
                if (curr.type === "cash-in") {
                    return acc + curr.amount
                } else {
                    return acc
                }
            }, 0), cashOut = row.cashInOut.reduce((acc, curr) => {
                if (curr.type === "cash-out") {
                    return acc + curr.amount
                } else {
                    return acc
                }
            }, 0), totalBox = (pesos + cashIn + debtPayments) - cashOut,
            info = {
                opening_date: row.opening_date,
                closing_date: row.closing_date,
                credit: row.closingTotal.credit,
                card: row.closingTotal.card,
                transfer: row.closingTotal.transfer || 0,
                pesos: pesos,
                debtPayment: debtPayments,
                cashIn: cashIn,
                cashOut: cashOut,
                opening: row.opening,
                dollars: row.closingTotal.dollars,
                totalBox: totalBox,
                totalCurrency: row.countedTotal.coins,
                totalBills: row.countedTotal.bills,
                totalDollars: row.countedTotal.dollars,
                totalCard: row.countedTotal.card,
                totalTransfer: row.countedTotal.transfer || 0,
                totalPesos: totalBox - (row.countedTotal.coins + row.countedTotal.bills) - cashOut,
                totalPesosCount: (row.countedTotal.coins + row.countedTotal.bills) - (totalBox + row.opening),
                totalDollarsCount: (row.countedTotal.dollars - row.closingTotal.dollars),
                totalCardsCount: (row.countedTotal.card - row.closingTotal.card),
                totalTransferCount: (row.countedTotal.transfer - row.closingTotal.transfer) || 0,
                user: row.uid[0].name,
                branch: row.boid[0].name
            }
        if (type === "view") {
            setModals({ ...modals, info: info, viewBoxClosures: true, boxClosures: false })
        } else {
            setPrint({ ...info, typePrint: "shiftClosure" })
        }
    }

    /**
     * Handle Select item
     */
    const handleSelectItem = (row, setModal) => {
        setSearchProduct(row.code)
        setModal(false)
    }

    /**
     * Handle add new product
     * @param {*} product 
     */
    const handleAddProduct = (product) => {
        const { price, description } = product.prices[0], item = {
            pid: product._id,
            code: product.code,
            description: product.description,
            price: price,
            qty: 1,
            chid: product.chid,
            quantity: product.quantity,
            saleType: description,
            cost: product.cost,
            amount: price,
            taxes: product.taxes,
            itbis: naiveRound(addTaxes(product.taxes, price), 2),
            totalItbis: naiveRound(addTaxes(product.taxes, price), 2),
            existence: product.qty,
            prices: product.prices,
            branch_offices: product.branch_offices
        }, itms = items.find(r => r.pid === product._id)
        if (itms) {
            handlePlusOrMinus(itms, 1)
            setForm({ ...form, code: "" })
        } else {
            if (items.length === 0) setSelectedRow(0)
            setItems([...items, item])
        }
    }

    /**
     * Handle change input
     * @param evt
     */
    const handleChange = (evt, opt = "", row) => {
        if (opt === "row") {
            const val = (parseFloat(cleanNumber(evt.target.value)) >= row.existence) ? row.existence : parseFloat(cleanNumber(evt.target.value))
            const qty = (val > 0) ? val : 1
            setItems(items.map((p) => (p.pid === row.pid ? {
                ...p,
                qty: qty,
                amount: qty * p.price,
                totalItbis: qty * p.itbis
            } : p)))
        } else if (opt) {
            setForm({ ...form, [opt]: evt })
        } else {
            const { name, value } = evt.target
            setForm({ ...form, [name]: value })
        }
    }

    /**
     * Handle find product by code
     * @param {*} evt
     */
    const handleSearchProduct = (evt) => {
        if (search && evt.key === 'Enter') {
            setSearchProduct(search, () => {
                setSearch("")
            })
        }
    }

    /**
     * Handle detect when you press a key
     * @param {*} event 
     */
    const handleKeyDown = async (event) => {
        if (["F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F9", "F10", "F12"].includes(event.key)) event.preventDefault()

        let newModal = [], newPrices = [], newItems = [], newSelected = null, newForm = null

        // Get modals
        await setModals(prevSetModals => {
            newModal = prevSetModals
            return prevSetModals
        })

        // Get prices
        await setPrices(prevSetPrices => {
            newPrices = prevSetPrices
            return prevSetPrices
        })

        // Get Items
        await setItems(prevItems => {
            newItems = prevItems
            return prevItems
        })

        // Get selected row
        await setSelectedRow(prevSelectedRow => {
            newSelected = prevSelectedRow
            return prevSelectedRow
        })

        // Get form
        await setForm(prevForm => {
            newForm = prevForm
            return prevForm
        })

        // Select the sale price
        if (newModal.salePrice && [49, 50, 51, 97, 98, 99].includes(event.keyCode)) {
            let num = ([49, 97].includes(event.keyCode)) ? 1 : ([50, 98].includes(event.keyCode) ? 2 : ([51, 99].includes(event.keyCode) ? 3 : -1))
            if (num > 0) handleShowChangePrice(newPrices.find((r, k) => k === (num - 1)), newItems, newSelected)
        }

        // Select the payment options
        if (newModal.paymentOptions && ["F1", "F2", "F3", "F4", "F5", "F10"].includes(event.key)) {
            if (event.key === "F1") document.querySelector("#optCash").click()
            if (event.key === "F2") document.querySelector("#optCard").click()
            if (event.key === "F3") document.querySelector("#optTransfer").click()
            if (newForm.customer.cid && event.key === "F4") document.querySelector("#optCredit").click()
            if (event.key === "F5") document.querySelector("#optCombined").click()
            if (event.key === "F10") document.querySelector("#completePayment").click()
        }

        // Check if modals and alerts are closed
        if (!newModal.customers && !newModal.invoiceType && !newModal.productInformation && !newModal.paymentOptions &&
            !document.querySelector('.react-confirm-alert-body-element') && !document.querySelector('#md-list-products') &&
            !document.querySelector('#md-customers') && !document.querySelector('#md-outstanding-debts') &&
            !document.querySelector('#md-pay-off-debt') && !newModal.bills && !newModal.openShift &&
            !newModal.cashInOut && !newModal.closeShift) {

            // Show products
            if (event.key === "F8") handleProducts()

            // Show Pay Bill
            if (newItems.length && event.key === "F10") handlepayBill()

            // Show closure list
            if (event.key === "F1") setModals({ ...modals, boxClosures: true })

            // Show bill history
            if (event.key === "F12") setModals({ ...modals, bills: true })

            // Show cash in and cash out
            if (event.key === "F3") setModals({ ...modals, cashInOut: true })

            // Show customers
            if (event.key === "F4") setModals({ ...modals, customers: true })

            // Show type invoice
            if (event.key === "F5") setModals({ ...modals, invoiceType: true })

            // Show type invoice
            if (event.key === "F7") document.querySelector("#optReset").click()


            /****************************************************************************************************************************
             * Table event
             */

            // Move down
            if (event.key === "ArrowDown") setSelectedRow((prevSelectedRow) => prevSelectedRow === items.length - 1 ? 0 : prevSelectedRow + 1)

            // Move up
            if (event.key === "ArrowUp") setSelectedRow((prevSelectedRow) => prevSelectedRow === 0 ? items.length - 1 : prevSelectedRow - 1)

            // Item table events
            if (["-", "+", "F9", "F2", "Delete"].includes(event.key)) {
                let newprevItems = []
                // Add or subtract quantity
                await setItems(prevItems => {
                    if (["-", "+"].includes(event.key)) {
                        const obj = prevItems.find((r, k) => k === newSelected), count = ((event.key === "+") ? +1 : -1)
                        if ((obj.qty >= 1 && count > 0) || (obj.qty > 1 && count < 0)) {
                            handlePlusOrMinus(obj, count, prevItems)
                        }
                    }
                    newprevItems = prevItems
                    return prevItems
                })

                // Identifies the selected row
                const row = newprevItems.find((r, k) => k === newSelected)
                if (row) {
                    // Remove item
                    if (event.key === "Delete") handleRemoveItem(row, "product", newprevItems)

                    // Show price change
                    if (event.key === "F2") handleShowSalePrice(row)

                    // Display item information
                    if (event.key === "F9") handleProductInformation(row)
                }
            }
        }
    }

    /**
     * Handle plus or minus
     * @param {*} pid 
     * @param {*} count 
     */
    const handlePlusOrMinus = (obj, count, prevItems = items) => {
        obj.qty += (obj.existence >= (obj.qty + count)) ? count : 0
        obj.amount = obj.qty * obj.price
        obj.totalItbis = obj.qty * obj.itbis
        handleSumTotal(prevItems)
    }

    /**
     * Handle remover item
     * @param {*} row 
     */
    const handleRemoveItem = (row, type = "", iList = []) => {
        if (type === "service") {
            confirmOption("Eliminar Servicio", "¿Realmente desea eliminar este servicio?", (onClose) => {
                const servicesList = services.data.filter(r => r.key !== row.key)
                setItems(servicesList)
                setServices({ ...services, data: servicesList })
                onClose()
            }, (onClose) => onClose(), "far fa-trash-alt")
        } else {
            confirmOption("Eliminar Producto", "¿Realmente desea eliminar este producto?", (onClose) => {
                const itemsList = ((iList.length) ? iList : items).filter(r => r.pid !== row.pid)
                setItems(itemsList)
                onClose()
            }, (onClose) => onClose(), "far fa-trash-alt")
        }
    }

    /**
     * Handle select the type of invoice
     * @param {*} type 
     */
    const handleInvoiceType = (type) => {
        if (type === "without-tax-receipt") {
            setForm({ ...form, invoiceType: { value: 'without-tax-receipt', label: 'Sin Comprobante' } })
        } else if (type === "final-consumer") {
            setForm({ ...form, invoiceType: { value: 'final-consumer', label: 'Consumidor Final' } })
        } else if (type === "fiscal-credit") {
            setForm({ ...form, invoiceType: { value: 'fiscal-credit', label: 'Crédito Fiscal' } })
        }
        setModals({ ...modals, invoiceType: false })
    }

    /**
     * Handle save invoice
     */
    const handleSaveInvoice = () => {
        setOnHold([...onHold, { form: form, items: items }])
        handleReset()
    }

    /**
     * Handle reset
     */
    const handleReset = () => {
        confirmOption("Cancelar Factura", "¿Realmente desea cancelar esta factura?", (onClose) => {
            setForm(initForm)
            setItems([])
            onClose()
        }, (onClose) => onClose(), "far fa-trash-alt")
    }

    /**
     * Handle show sale price
     * @param {*} row 
     */
    const handleShowSalePrice = (row) => {
        setPrices(row.prices)
        setForm({ ...form, selectedRow: row.pid })
        setModals({ ...modals, salePrice: true })
    }

    /**
     * Handle show change price confirm
     * @param {*} row 
     * @param {*} itms 
     * @param {*} key 
     */
    const handleShowChangePrice = (row, itms = items, key = selectedRow) => {
        confirmOption("Cambiar precio de venta", "¿Deseas cambiar el precio de venta de todos los productos?", (onClose) => {
            handleChangePrice(row, itms, key, true)
            onClose()
        }, (onClose) => {
            handleChangePrice(row, itms, key)
            onClose()
        }, "fas fa-exchange-alt")
    }

    /**
     * Handle change price
     * @param {*} row 
     * @param {*} itms 
     * @param {*} key 
     * @param {*} all 
     */
    const handleChangePrice = (row, itms, key, all = false) => {
        const itemsList = []
        itms.forEach((r, k) => {
            const price = row.price || 0
            if (price > 0) {
                if (k === key || all) {
                    r.saleType = row.description
                    r.price = row.price
                    r.amount = r.qty * r.price
                    r.itbis = naiveRound(addTaxes(r.taxes, row.price), 2)
                    r.totalItbis = r.qty * r.itbis
                }
            }
            itemsList.push(r)
        })
        setItems(itemsList)
        setModals({ ...modals, salePrice: false })
        setPrices([])
    }

    /**
     * Handle get 
     */
    const handleGetCustomers = () => {
        getCustomers()
    }

    /**
     * Handle validated
     * @param {*} type 
     * @returns 
     */
    const handleValidated = (type = "") => {
        setErrors({})
        if (type === "cash") {
            if (!form.pesos) { setErrors({ pesos: true }); return false; }
        } else if (type === "card") {
            if (!form.card) { setErrors({ card: true }); return false; }
            else if (!form.approval) { setErrors({ approval: true }); return false; }
        } else if (type === "transfer") {
            if (form.idBank.length <= 0) { setErrors({ idBank: true }); return false; }
            else if (!form.transApproval) { setErrors({ transApproval: true }); return false; }
        } else if (type === "opening") {
            if (!form.opening) { setErrors({ opening: true }); return false; }
        } else if (type === "closing") {
            if (!form.totalCurrency) { setErrors({ totalCurrency: true }); return false; }
            else if (!form.totalBills) { setErrors({ totalBills: true }); return false; }
            else if (!form.totalDollars) { setErrors({ totalDollars: true }); return false; }
            else if (!form.totalCard) { setErrors({ totalCard: true }); return false; }
            else if (!form.totalTransfer) { setErrors({ totalTransfer: true }); return false; }
        } else if (type === "cashInOut") {
            if (form.typeCashInOut.length <= 0) { setErrors({ typeCashInOut: true }); return false; }
            else if (!form.amount) { setErrors({ amount: true }); return false; }
            else if (!form.comment) { setErrors({ comment: true }); return false; }
        } else if (type === "service") {
            if (form.svr.length <= 0) { setErrors({ svr: true }); return false; }
            else if (form.empsvr.length <= 0) { setErrors({ empsvr: true }); return false; }
            else if (!form.countSrv) { setErrors({ countSrv: true }); return false; }
        }
        return true;
    }

    /**
     * Handle paymentoptions
     * @param {*} option
     */
    const handlePaymentOptions = (option) => {
        const { active, rate } = settings.ccRate
        if (active) {
            if (option === "card" && (totals.ccTax || 0) <= 0) {
                const ccTax = totals.subTotal * (rate / 100), ccTaxItbis = ccTax * 0.18, itbis = totals.itbis + ccTaxItbis, subTotal = totals.subTotal + ccTax
                setTotals({ ...totals, subTotal: subTotal, ccTax: ccTax, itbis: itbis, total: subTotal + itbis })
            } else if (option !== "card" && totals.ccTax > 0) {
                const ccTaxItbis = totals.ccTax * 0.18, subTotal = totals.subTotal - totals.ccTax, itbis = totals.itbis - ccTaxItbis
                setTotals({ ...totals, subTotal: subTotal, itbis: itbis, ccTax: 0, total: subTotal + itbis })
            }
        }
        setErrors({})
        setForm({
            ...form, pesos: "", dollars: "", dollarsChange: "", card: "", approval: "", idBank: [],
            creditAmount: "", transferAmount: "", transApproval: "", paymentOption: option
        })
    }

    /**
     * Handle complete payment 
     */
    const handleCompletePayment = () => {
        if (form.payable > 0 && ["cash", "combined"].includes(form.paymentOption)) {
            setErrors({ payable: true })
            setTimeout(() => setErrors({}), 10000)
            return false
        }

        if (handleValidated(form.paymentOption)) {
            setModals({ ...modals, paymentOptions: false })
            const cash = [], card = [], subTotal = parseFloat(cleanNumber(totals.subTotal)),
                transfer = [], tax = parseFloat(cleanNumber(totals.itbis)),
                peso = parseFloat(cleanNumber(form.pesos)), /*- parseFloat(cleanNumber(form.change))*/
                cTax = (totals.ccTax) ? totals.ccTax : 0, creditAmount = cleanNumber(form.creditAmount) || 0,
                credit = (form.paymentOption === "credit" && !creditAmount) ? (subTotal + tax + cTax) : creditAmount

            // Products
            const products = items.map((r) => ({
                pid: r.pid,
                quantity: r.qty,
                tax: r.itbis,
                price: r.price,
                cost: r.cost,
                saleType: r.saleType,
                description: r.description
            }))

            // Services
            const svrs = services.data.map((r) => ({
                pid: r.srid.value,
                eid: r.eid.value,
                quantity: r.count
            }))

            // If payment option is cash or combined
            if (["cash", "combined"].includes(form.paymentOption)) {
                if (form.pesos) cash.push({ currency: "peso", amount: (form.paymentOption === "cash") ? naiveRound(subTotal, 2) + naiveRound(tax, 2) : naiveRound(peso, 2) })
                if (form.dollars) cash.push({ currency: "dollars", amount: naiveRound(parseFloat(cleanNumber(form.dollars)), 2), rate: settings?.rate })
            }

            // If payment option is card or combined
            if (["card", "combined"].includes(form.paymentOption)) {
                const { active, rate } = settings.ccRate, amountCard = (form.paymentOption === "combined") ? parseFloat(cleanNumber(form.cardAmount)) : totals.total
                card.push({
                    amount: naiveRound(amountCard, 2),
                    card: form.card,
                    rate: (active) ? rate : 0,
                    approval: form.approval
                })
            }

            // If payment option is transfer or combined
            if (["transfer", "combined"].includes(form.paymentOption)) {
                const amountTrans = (form.paymentOption === "combined") ? parseFloat(cleanNumber(form.transferAmount)) : totals.total
                transfer.push({
                    amount: naiveRound(amountTrans, 2),
                    bkid: form.idBank.value,
                    approval: form.transApproval
                })
            }

            setBill({
                invoiceType: form.invoiceType.value,
                cid: form.customer.cid || "",
                discount: "",
                subTotal: naiveRound(subTotal, 2),
                tax: naiveRound(tax, 2),
                ccTax: naiveRound(cTax, 2),
                products: products,
                services: svrs,
                paymentOption: form.paymentOption,
                cash: cash,
                card: card,
                credit: naiveRound(credit, 2),
                transfer: transfer,
                shift: shifts.id
            }, (data) => {
                setPrint({
                    typePrint: "invoice",
                    invoiceNumber: data.invoiceNumber,
                    tax: tax,
                    ncf: data.ncf || "",
                    invoiceType: data.invoiceType,
                    subTotal: subTotal,
                    products: products,
                    cid: form.customer,
                    paymentOption: form.paymentOption,
                    created: data.created,
                    ccTax: cTax,
                    change: (form.paymentOption === "cash") ? form.change : null,
                    cashPaid: (form.paymentOption === "cash") ? peso : null
                })
                setForm(initForm)
                setServices({ opt: [], emps: [], data: [] })
                setItems([])
            })
        }
    }

    /**
     * Handle select customer
     * @param {*} row 
     */
    const handleSelectedCustomer = (row) => {
        setForm({ ...form, customer: { cid: row._id, name: ucwords(row.name), locked: row.locked } })
        setModals({ ...modals, customers: false })
    }

    /**
     * Handle open shift
     */
    const handleOpenShift = () => {
        if (handleValidated("opening")) {
            setOpenShift({ opening: parseFloat(cleanNumber(form.opening)) })
        }
    }

    /**
     * Handle close shift
     */
    const handleCloseShift = () => {
        if (handleValidated("closing")) {
            const closingTotal = {
                cash: modals.info?.pesos,
                dollars: modals.info?.dollars,
                card: modals.info?.card,
                credit: modals.info?.credit,
                transfer: modals.info?.transfer,
                debtPayments: modals.info?.debtPayment
            }, countedTotal = {
                coins: parseFloat(cleanNumber(form.totalCurrency)),
                bills: parseFloat(cleanNumber(form.totalBills)),
                dollars: parseFloat(cleanNumber(form.totalDollars)),
                card: parseFloat(cleanNumber(form.totalCard)),
                transfer: parseFloat(cleanNumber(form.totalTransfer)),
                rate: settings?.rate
            }
            setUpdateShift(shifts.id, {
                closingTotal: closingTotal,
                countedTotal: countedTotal,
                status: "closing"
            })
        }
    }

    /**
     * Handle get list of products by branch offices
     */
    const handleProducts = () => {
        getProductsByBranchOffices()
    }

    /**
     * Handle product information
     * @param {*} row 
     */
    const handleProductInformation = (row) => {
        getProduct(row.pid)
    }

    /**
     * Handle add cash in out
     */
    const handleAddCashInOut = () => {
        if (handleValidated("cashInOut")) {
            const listCashInOut = []
            shifts.cashInOut.forEach(r => {
                listCashInOut.push({
                    type: r.type,
                    amount: parseFloat(r.amount),
                    comment: r.comment
                })
            })
            listCashInOut.push({
                type: form.typeCashInOut.value,
                amount: cleanNumber(form.amount),
                comment: form.comment
            })
            setShifts({ ...shifts, cashInOut: listCashInOut })
            setUpdateShift(shifts.id, { cashInOut: listCashInOut }, () => {
                setForm({ ...form, typeCashInOut: [], amount: "", comment: "" })
            })
        }
    }

    /**
     * Handle remove cash in out
     * @param {*} row
     */
    const handleCashInOut = (key) => {
        const listCashInOut = shifts.cashInOut.filter((r, index) => index !== key)
        setShifts({ ...shifts, cashInOut: listCashInOut })
        setUpdateShift(shift)
    }

    /**
     * Handle add employee
     */
    const handleAddEmpl = () => {
        if (handleValidated("service")) {
            let count = form.svr.count, countTable = (services.data.find(r => form.svr?.value === r.srid?.value)?.count || 0)
            if (count < (countTable + parseInt(form.countSrv))) {
                confirmError("Error límite excedido", `La cantidad no puede ser mayor a ${count - countTable}`)
            } else {
                let serviceData = services.data, edit = false
                serviceData.forEach(obj => {
                    if (obj.srid.value === form.svr?.value && obj.eid.value === form.empsvr.value) {
                        obj.count += parseInt(form.countSrv)
                        edit = true
                    }
                })
                setServices({ ...services, data: (!edit) ? [...services.data, { key: services.data.length + 1, srid: form.svr, eid: form.empsvr, count: parseInt(form.countSrv) }] : serviceData })
                setForm({ ...form, svr: [], empsvr: [], countSrv: "" })
            }
        }
    }

    /**
     * Handle pay bill
     */
    const handlepayBill = () => {
        if (services.opt.length) {
            getEmployees()
        } else {
            setModals({ ...modals, paymentOptions: true })
        }
    }

    /**
     * Handle validates the total service and shows pay bill
     */
    const handleValidatesServicePayBill = () => {
        let count = services.data.reduce((a, c) => a + parseInt(c.count), 0),
            countTable = services.opt.reduce((a, c) => a + parseInt(c.count), 0)
        if (count !== countTable) {
            confirmError("Servicios pendiente", `Existen servicios sin asignación, favor de asignar estos servicios a los empleados para continuar.`)
        } else {
            setModals({ ...modals, employees: false, paymentOptions: true })
        }
    }

    return (
        <>
            <div style={{ display: "none" }}>
                <PrintInvoiceReceipt ref={printInvoiceReceipt} print={print} />
                <PrintShiftClosure ref={printShiftClosure} print={print} />
            </div>
            <View
                auth={auth}
                form={form}
                bill={bill}
                shift={shift}
                items={items}
                modals={modals}
                onHold={onHold}
                prices={prices}
                errors={errors}
                shifts={shifts}
                totals={totals}
                config={config}
                setForm={setForm}
                setPrint={setPrint}
                options={options}
                settings={settings}
                services={services}
                customer={customer}
                setModals={setModals}
                dateRange={dateRange}
                getCustomers={getCustomers}
                handleReset={handleReset}
                selectedRow={selectedRow}
                handleChange={handleChange}
                handlepayBill={handlepayBill}
                handleAddEmpl={handleAddEmpl}
                handleProducts={handleProducts}
                handleOpenShift={handleOpenShift}
                handleRemoveItem={handleRemoveItem}
                setSelectedRow={setSelectedRow}
                handlePlusOrMinus={handlePlusOrMinus}
                handleInvoiceType={handleInvoiceType}
                handleSaveInvoice={handleSaveInvoice}
                handleShowChangePrice={handleShowChangePrice}
                search={search} setSearch={setSearch}
                columnsCashInOut={columnsCashInOut}
                handleCloseShift={handleCloseShift}
                handleBoxClosures={handleBoxClosures}
                handleAddCashInOut={handleAddCashInOut}
                columnsBoxClosures={columnsBoxClosures}
                handleShowSalePrice={handleShowSalePrice}
                handleSearchProduct={handleSearchProduct}
                handlePaymentOptions={handlePaymentOptions}
                handleCompletePayment={handleCompletePayment}
                getCashierClosingShift={getCashierClosingShift}
                handleProductInformation={handleProductInformation}
                handleSelectItem={handleSelectItem}
                handleGetCustomers={handleGetCustomers}
                handleSelectedCustomer={handleSelectedCustomer}
                handleValidatesServicePayBill={handleValidatesServicePayBill} />
        </>
    )
}

const mapStateToProps = ({ auth, config, inventory, customer, bill, shift, humanResources }) => ({
    auth,
    bill,
    shift,
    config,
    customer,
    inventory,
    humanResources
})

const mapDispatchToProps = () => ({
    ...humanResourcesActions,
    ...inventoryActions,
    ...customerActions,
    ...configActions,
    ...shiftActions,
    ...billActions
})

export default connect(mapStateToProps, mapDispatchToProps())(PointOfSale)